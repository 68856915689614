import { useParams, useNavigate } from "react-router-dom";
import { useGetBookingsQuery } from "../bookings/bookingsApiSlice";
import { HashLoader } from "react-spinners";
import { FaArrowLeft } from "react-icons/fa";

function calculateDuration(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const differenceInDays = Math.round((end - start) / (1000 * 60 * 60 * 24));
  return differenceInDays + " days";
}

const DashboardCustomerOrderPage2 = () => {
  const { bookingId } = useParams();

  const { booking } = useGetBookingsQuery("bookingsList", {
    selectFromResult: ({ data }) => ({
      booking: data?.entities[bookingId],
    }),
  });

  const navigate = useNavigate();

  let content;

  if (!booking) {
    return <HashLoader color="#36d7b7" />;
  }

  // console.log(booking);

  if (booking) {
    content = (
      <div className="dashboardCustomerOrderPage2">
        <div className="dashboardCustomerOrderPage2OrderAndBack">
          <h2>Order #{booking.id}</h2>{" "}
          <button type="button" className="" onClick={() => navigate(-1)}>
            <FaArrowLeft className="back-button-icon-customerDashboardOrderPage" />
            Back
          </button>
        </div>
        <div className="dashboardCustomerOrderPage2Container">
          <div className="dashboardCustomerOrderPage2ContainerFinancial">
            <div className="dashboardCustomerOrderPage2ContainerFinancialImg">
              <img src={booking.vehicleDetails?.images?.[0]} alt="" />
            </div>
            <div className="dashboardCustomerOrderPage2ContainerFinancialTable">
              <table>
                <thead>
                  <tr>
                    <th>Vehicle</th>
                    <th>Day Rate</th>
                    <th>Duration</th>
                    <th>Extra Driver Fee</th>
                    <th>Chauffeur Fee</th>
                    <th>Delivery Fee</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Add table rows here */}
                  <tr>
                    {/* Replace placeholders with actual data */}
                    <td>
                      {booking.vehicleDetails.make}{" "}
                      {booking.vehicleDetails.model}{" "}
                      {booking.vehicleDetails.modelVariant}
                    </td>
                    <td>{booking.vehicleDetails.pricePerDay}/Day</td>
                    <td>
                      {/* Format dates conditionally */}
                      {booking.startDate instanceof Date
                        ? booking.startDate.toLocaleDateString("en-GB")
                        : new Date(booking.startDate).toLocaleDateString(
                            "en-GB"
                          )}{" "}
                      -{" "}
                      {booking.endDate instanceof Date
                        ? booking.endDate.toLocaleDateString("en-GB")
                        : new Date(booking.endDate).toLocaleDateString("en-GB")}
                      <br />
                      {"Duration: " +
                        calculateDuration(booking.startDate, booking.endDate)}
                    </td>
                    <td>{booking.additionalDriverFee || "£0.00"}</td>
                    <td>{booking.chauffeurFee || "£0.00"}</td>
                    <td>{booking.deliveryFee || "£0.00"}</td>
                    <td>£{booking.totalPrice}</td>
                  </tr>
                  <tr>
                    <td>{booking.vehicleDetails.registration}</td>
                    <td>{booking.vehicleDetails.mileageLimit} Miles per Day</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="dashboardCustomerOrderPage2CustomerDetails">
            <div className="dashboardCustomerOrderPage2CustomerDetailsTopHalf">
              <h4>Customer Details</h4>
              <p>
                {booking.customer.firstname} {booking.customer.lastname} - Main
                Driver
              </p>
            </div>
            <div className="dashboardCustomerOrderPage2CustomerDetailsBottomHalf">
              <div className="dashboardCustomerOrderPage2CustomerDetailsBottomHalfColumn">
                <h5>Personal</h5>
                <p>
                  Date of Birth -{" "}
                  {new Date(
                    booking.mainCustomer.dateOfBirth
                  ).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                </p>
                <p>Mobile Number - {booking.customer.telephone}</p>
                <p>Email Address - {booking.customer.email}</p>
                <p>
                  Home Address - {booking.mainCustomer.address.line1} <br />
                  {booking.mainCustomer.address.line2}{" "}
                  {booking.mainCustomer.address.city}{" "}
                  {booking.mainCustomer.address.county}{" "}
                  {booking.mainCustomer.address.postCode}
                </p>
              </div>
              <div className="dashboardCustomerOrderPage2CustomerDetailsBottomHalfColumn">
                <h5>Driving license</h5>
                <p>
                  Driving License Number -{" "}
                  {booking.mainCustomer.drivingLicense.number}
                </p>
                <p>
                  Date of Issue -{" "}
                  {new Date(
                    booking.mainCustomer.drivingLicense.dateOfIssue
                  ).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                </p>
                <p>
                  Date of Expiry -{" "}
                  {new Date(
                    booking.mainCustomer.drivingLicense.dateOfExpiry
                  ).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                </p>
                <p>
                  Place of Issue -{" "}
                  {booking.mainCustomer.drivingLicense.placeOfIssue}
                </p>
              </div>
              <div className="dashboardCustomerOrderPage2CustomerDetailsBottomHalfColumn">
                <h5>Passport Details</h5>
                <p>Passport Number - {booking.mainCustomer.passport.number}</p>
                <p>
                  Date of Issue -{" "}
                  {new Date(
                    booking.mainCustomer.passport.dateOfIssue
                  ).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                </p>
                <p>
                  Date of Expiry -{" "}
                  {new Date(
                    booking.mainCustomer.passport.dateOfExpiry
                  ).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                </p>
              </div>
            </div>
          </div>
          {booking?.additionalDriver?.customer && (
            <div className="dashboardCustomerOrderPage2CustomerDetails">
              <div className="dashboardCustomerOrderPage2CustomerDetailsTopHalf">
                <h4>Additional Customer Details</h4>
                <p>{booking.additionalDriver.customer} - Additional Driver</p>
              </div>
              <div className="dashboardCustomerOrderPage2CustomerDetailsBottomHalf">
                <div className="dashboardCustomerOrderPage2CustomerDetailsBottomHalfColumn">
                  <h5>Personal</h5>
                  <p>
                    Date of Birth -{" "}
                    {new Date(
                      booking.additionalDriver.dateOfBirth
                    ).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </p>
                  <p>Mobile Number - {booking.additionalDriver.telephone}</p>
                  <p>Email Address - {booking.additionalDriver.email}</p>
                  <p>
                    Home Address - {booking.additionalDriver.address.line1}{" "}
                    <br />
                    {booking.additionalDriver.address.line2}{" "}
                    {booking.additionalDriver.address.city}{" "}
                    {booking.additionalDriver.address.county}{" "}
                    {booking.additionalDriver.address.postCode}
                  </p>
                </div>
                <div className="dashboardCustomerOrderPage2CustomerDetailsBottomHalfColumn">
                  <h5>Driving license</h5>
                  <p>
                    Driving License Number -{" "}
                    {booking.additionalDriver.drivingLicense.number}
                  </p>
                  <p>
                    Date of Issue -{" "}
                    {new Date(
                      booking.additionalDriver.drivingLicense.dateOfIssue
                    ).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </p>
                  <p>
                    Date of Expiry -{" "}
                    {new Date(
                      booking.additionalDriver.drivingLicense.dateOfExpiry
                    ).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </p>
                  <p>
                    Place of Issue -{" "}
                    {booking.additionalDriver.drivingLicense.placeOfIssue}
                  </p>
                </div>
                <div className="dashboardCustomerOrderPage2CustomerDetailsBottomHalfColumn">
                  <h5>Passport Details</h5>
                  <p>
                    Passport Number - {booking.additionalDriver.passport.number}
                  </p>
                  <p>
                    Date of Issue -{" "}
                    {new Date(
                      booking.additionalDriver.passport.dateOfIssue
                    ).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </p>
                  <p>
                    Date of Expiry -{" "}
                    {new Date(
                      booking.additionalDriver.passport.dateOfExpiry
                    ).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="dashboardCustomerOrderPage2BookingProgress">
            {" "}
            <h4>Booking Progress</h4>
            <p>{booking.bookingProgress}</p>
          </div>
          <div className="dashboardCustomer2OrderPageDurationDetails">
            {" "}
            <h4>Duration</h4>
            <table>
              <thead>
                <tr>
                  <th>Start</th>
                  <th>End</th>
                  <th>Duration</th>
                  <th>Pick Up Location</th>
                  <th>Return Location</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {" "}
                    {booking.startDate instanceof Date
                      ? booking.startDate.toLocaleDateString("en-GB")
                      : new Date(booking.startDate).toLocaleDateString(
                          "en-GB"
                        )}{" "}
                    <br />
                    {booking.startTime}
                  </td>
                  <td>
                    {booking.endDate instanceof Date
                      ? booking.endDate.toLocaleDateString("en-GB")
                      : new Date(booking.endDate).toLocaleDateString("en-GB")}
                    <br />
                    {booking.endTime}
                  </td>
                  <td>
                    {calculateDuration(booking.startDate, booking.endDate)}
                  </td>
                  {/* <td>{booking.pickUpLocationInDepth.type}</td> */}
                  <td>
                    {booking.pickUpLocationInDepth.type}
                    {booking.pickUpLocationInDepth.type === "Custom" && (
                      <div>
                        <p>
                          Address Line 1:{" "}
                          {booking.pickUpLocationInDepth.address.line1}
                        </p>
                        <p>
                          Address Line 2:{" "}
                          {booking.pickUpLocationInDepth.address.line2}
                        </p>
                        <p>
                          City: {booking.pickUpLocationInDepth.address.city}
                        </p>
                        <p>
                          County: {booking.pickUpLocationInDepth.address.county}
                        </p>
                        <p>
                          Postcode:{" "}
                          {booking.pickUpLocationInDepth.address.postCode}
                        </p>
                      </div>
                    )}
                    {booking.pickUpLocationInDepth.type === "Dealership" && (
                      <div>
                        <p>
                          Address Line 1:{" "}
                          {
                            booking.vehicleDetails.address
                              .houseNumberAndStreetName
                          }
                        </p>
                        <p>
                          Address Line 2:{" "}
                          {booking.vehicleDetails.address.locality}
                        </p>
                        <p>City: {booking.vehicleDetails.address.cityOrTown}</p>
                        <p>Country: {booking.vehicleDetails.address.country}</p>
                        <p>
                          Postcode: {booking.vehicleDetails.address.postcode}
                        </p>
                      </div>
                    )}
                  </td>

                  <td>
                    {booking.dropOffLocationInDepth.type}
                    {booking.dropOffLocationInDepth.type === "Custom" && (
                      <div>
                        <p>
                          Address Line 1:{" "}
                          {booking.dropOffLocationInDepth.address.line1}
                        </p>
                        <p>
                          Address Line 2:{" "}
                          {booking.dropOffLocationInDepth.address.line2}
                        </p>
                        <p>
                          City: {booking.dropOffLocationInDepth.address.city}
                        </p>
                        <p>
                          County:{" "}
                          {booking.dropOffLocationInDepth.address.county}
                        </p>
                        <p>
                          Postcode:{" "}
                          {booking.dropOffLocationInDepth.address.postCode}
                        </p>
                      </div>
                    )}
                    {booking.dropOffLocationInDepth.type === "Dealership" && (
                      <div>
                        <p>
                          Address Line 1:{" "}
                          {
                            booking.vehicleDetails.address
                              .houseNumberAndStreetName
                          }
                        </p>
                        <p>
                          Address Line 2:{" "}
                          {booking.vehicleDetails.address.locality}
                        </p>
                        <p>City: {booking.vehicleDetails.address.cityOrTown}</p>
                        <p>Country: {booking.vehicleDetails.address.country}</p>
                        <p>
                          Postcode: {booking.vehicleDetails.address.postcode}
                        </p>
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="dashboardCustomerOrderPage2BookingProgress">
            {" "}
            <h4>Extra Details</h4>
            <p>{booking.bookingStatus}</p>
          </div>
        </div>
      </div>
    );
  }

  return content;
};

export default DashboardCustomerOrderPage2;
