import VehicleList from "../features/vehicles/VehicleList";
import Footer from "./Footer";
import HomeSideBar from "./HomeSideBar";
import { DateRangeProvider } from "../context/DateRangeContext";
import { useEffect, useState } from "react";

const Home = () => {
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFooter(true); // Show the footer after 2 seconds
    }, 2000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  const content = (
    <>
      <DateRangeProvider>
        <main>
          <HomeSideBar />
          <VehicleList />
        </main>
        {/* <Footer /> */}
        {showFooter && <Footer />}{" "}
        {/* Footer is only rendered after 2 seconds */}
      </DateRangeProvider>
    </>
  );

  return content;
};

export default Home;
