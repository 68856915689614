import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import img1 from "./landingPageImgsCustomer/Screenshot 2024-11-28 at 16.24.32.png";

// Dynamically import all images in the folder
const importAll = (r) => r.keys().map(r);
const images = importAll(
  require.context("./landingPageImgs", false, /\.(png|jpe?g|svg)$/)
);

const importAllCustomer = (r) => r.keys().map(r);

const imagesCustomer = importAllCustomer(
  require.context("./landingPageImgsCustomer", false, /\.(png|jpe?g|svg)$/)
);

const faqItems = [
  {
    question: "What is this and how does it work?",
    answer: (
      <>
        This is a booking platform/software where you can list your vehicles and
        customers can book through the site. All you do is create a vehicle and
        add very specific parameters such as days the vehicle is available,
        minimum age, etc. Once this is complete, you are ready to go! Just share
        the link to your dealership under the Customer tab on the dashboard.
        When an order comes through, it arrives as a request, which you can then
        accept or decline. But it doesn't stop there; you can also view sales
        data, customer data, easily see when vehicles are due to go in/out, plus
        much more. Here is a link to our {""}
        <a
          href="https://youtu.be/lsvHFXmsAdg?si=XIZr-oTfvlD1DRvI"
          target="_blank"
          rel="noopener noreferrer"
        >
          YouTube channel
        </a>
        {""} for a full in-depth walkthrough.
      </>
    ),
  },
  {
    question: "How Does This Benefit Me?",
    answer: (
      <>
        Our software helps dealerships save countless hours by automating key
        processes and enhancing customer interactions:
        <br />
        - Effortlessly list vehicles and allow customers to book directly,
        eliminating back-and-forth communication.
        <br />
        - Pre-screen and filter unsuitable customers based on your dealership's
        specific requirements, such as age or other factors of your choice,
        ensuring only qualified leads can book. This reduces wasted time dealing
        with unqualified inquiries.
        <br />
        - Gain quick access to detailed customer information, including booking
        history, preferences, and eligibility, all in one place.
        <br />
        - Receive real-time insights into sales, booking trends, and vehicle
        availability, allowing you to make smarter decisions.
        <br />
        - Save time by automating repetitive tasks, freeing you up to focus on
        growing your business and boosting profitability.
        <br />
        - Improve customer satisfaction by offering a seamless, professional
        booking experience tailored to their needs.
        <br />
      </>
    ),
  },
  {
    question: "How Long Does It Take To Get Set Up?",
    answer:
      "You will be up and running within a few minutes. Firstly to create an account requires a few details and only takes around 30s to make. When choosing a plan you need to enter a few more details which takes around 30s. Then you are in. To Add a Vehicle takes around 1-2 Mins to fill in the required parameters. To Add previous bookings also takes around 1-2Mins. ",
  },
  {
    question: "What About Bookings I Get Via Phone or Email?",
    answer:
      "Bookings you get from elsewhere you can add under the Manual Orders Tab. That way the vehicle cannot be booked during those dates. This way it still allows you to track sales data and you still get reminders on when the vehicle is due to go in/out. ",
  },
  {
    question: "What if my vehicle is damaged?",
    answer:
      "You can mark your vehicle as damaged and there is page under the Reports tab which shows damaged vehicles. If the vehicle is in a major collision you can disable the vehicle from being booked any more by editing the vehicle and de-selecting the Available Checkbox. We are working on implementing a feature where you can upload before and after images to each booking.",
  },
  {
    question: "Can My Customer Pay Me Through the Website",
    answer:
      "Currently we do not accept payments through the site. We are working to obtain FCA Approval and expect to have this feature available either late decemeber or early Q1 2025",
  },
  {
    question: "Is There a Free Trial?",
    answer:
      "Yes, there is a free trial. All plans come with a 5 day free trial. You will only be charged once those 5 days are over. You can cancel anytime to avoid being charged, just head over to the Profile tab and manage your plan from there.",
  },
  {
    question: "I Want a Feature That is Not Available Yet, How Can i Add This",
    answer:
      "We frequently roll out updates adding more and more features. If you want to suggest a feature email IMAS-Cars@outlook.com with your request. Alternatively contact our sales representative.",
  },
];

const DealershipLandingPage = () => {
  const [slide, setSlide] = useState(0);

  const nextSlide = () => {
    setSlide(slide === images.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? images.length - 1 : slide - 1);
  };

  const [slideCustomer, setSlideCustomer] = useState(0);

  const nextSlideCustomer = () => {
    setSlideCustomer(
      slideCustomer === imagesCustomer.length - 1 ? 0 : slideCustomer + 1
    );
  };

  const prevSlideCustomer = () => {
    setSlideCustomer(
      slideCustomer === 0 ? imagesCustomer.length - 1 : slideCustomer - 1
    );
  };

  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }

    setSelected(i);
  };

  const navigate = useNavigate();

  const createAccountOnClick = () => {
    navigate("/users/newDealership");
  };

  const handleTermsClick = () => {
    navigate("/termsAndConditions"); // Navigate to the terms and conditions page
  };

  const handlePrivClick = () => {
    navigate("/privacyPolicy"); // Navigate to the terms and conditions page
  };

  const handleCookiesClick = () => {
    navigate("/cookiesPolicy"); // Navigate to the terms and conditions page
  };

  const content = (
    <div className="dealershipInfo">
      <div className="dealershipLandingPageTopHalf">
        {/* <h1>Manage All Your Bookings in One Place</h1>
        <p>Use the Image Slider to View What The Site Has To Offer</p>
        <p>
          Save Many Hours Going Back and Fourth With Customers About Their
          Details
        </p>
        <p>
          All Plans Come With a 5 Day Free Trial Which You Can Cancel Anytime
        </p>
        <p>Takes Only a Few Minutes To Get Set Up and Taking Orders</p>
        <p>Click the Get Access Button Now to Enter Into Your Dashboard</p>
        <button onClick={createAccountOnClick}>Get Access</button> */}
        <h1>Manage All Your Bookings in One Place</h1>
        {/* <p> -- Save countless hours and streamline your booking process</p>
        <p>-- Provide a professional booking experience your customers</p>
        <p>
          -- Instantly pre-qualify customers and reduce back-and-forth
          communication
        </p>
        <p> -- Enjoy a 5-day free trial—cancel anytime, risk-free</p>
        <p>-- Set up your account in minutes and start taking orders today</p>
        <p>Don’t miss out—click below to get access to your dashboard</p> */}

        <li>Eliminate The Need For a Booking Line</li>
        <li>Provide a professional booking experience for your customers</li>
        <li>
          Instantly pre-qualify customers and reduce back-and-forth
          communication
        </li>
        <li>Enjoy a 5-day free trial—cancel anytime</li>
        <li>Set up your account in minutes and start taking orders today</li>
        <li>Don’t miss out—click below to get access to your dashboard</li>

        <button onClick={createAccountOnClick}>Get Access</button>
      </div>
      <div className="dealershipLandingPageMiddle">
        <h1>Your Dashboard Preview</h1>
      </div>
      <div className="dealershipLandingPageBottomHalf">
        <div className="imagesCollection-DLP">
          <FaArrowCircleLeft
            onClick={prevSlide}
            className="arrow-DLP arrow-left-DLP"
          />
          {images.map((item, idx) => (
            <img
              src={item}
              alt={`Slide ${idx + 1}`}
              key={idx}
              className={
                slide === idx ? "slide-DLP" : "slide-DLP slide-hidden-DLP"
              }
            />
          ))}
          <FaArrowCircleRight
            onClick={nextSlide}
            className="arrow-DLP arrow-right-DLP"
          />
          <span className="indicators-DLP">
            {images.map((_, idx) => (
              <button
                key={idx}
                className={
                  slide === idx
                    ? "indicator-DLP"
                    : "indicator-DLP indicator-inactive-DLP"
                }
                onClick={() => setSlide(idx)}
              ></button>
            ))}
          </span>
        </div>
      </div>
      <div className="dealershipLandingPageCustomerView">
        <h1>Your Customers View</h1>
        <div className="dealershipLandingPageCustomerViewBackground">
          <div className="imagesCollection-DLP-customer">
            <FaArrowCircleLeft
              onClick={prevSlideCustomer}
              className="arrow-DLP arrow-left-DLP"
            />
            {imagesCustomer.map((item, idx) => (
              <img
                src={item}
                alt={`Slide ${idx + 1}`}
                key={idx}
                className={
                  slideCustomer === idx
                    ? "slide-DLP"
                    : "slide-DLP slide-hidden-DLP"
                }
              />
            ))}
            <FaArrowCircleRight
              onClick={nextSlideCustomer}
              className="arrow-DLP arrow-right-DLP"
            />
            <span className="indicators-DLP">
              {imagesCustomer.map((_, idx) => (
                <button
                  key={idx}
                  className={
                    slideCustomer === idx
                      ? "indicator-DLP"
                      : "indicator-DLP indicator-inactive-DLP"
                  }
                  onClick={() => setSlideCustomer(idx)}
                ></button>
              ))}
            </span>
          </div>
        </div>
      </div>
      <div className="dealershipLandingPageTable">
        <h1>Table Of Features</h1>
        <div className="dealershipLandingPageTableOfFeatures">
          <div className="dealershipLandingPageTableOfFeaturesTop">
            <div className="dealershipLandingPageTableOfFeaturesTopHeadingsLeft">
              <h2>Features You Get</h2>
            </div>
            <div className="dealershipLandingPageTableOfFeaturesTopHeadingsRight">
              <h2>What You Need to Do</h2>
            </div>
          </div>
          {/* <div className="dealershipLandingPageTableOfFeaturesBottom"> */}{" "}
          <div className="dealershipLandingPageTableOfFeaturesBottom2">
            <div className="dealershipLandingPageTableOfFeaturesTopContentLeft">
              <li>Eliminate the Need For a Booking Line</li>
              <li>Customers Can Book Through The Site</li>
              <li>You Can Create As Many Vehicles As You Want</li>
              <li>
                Pre-Qualify Customers by Adding Specific Requirements Such as
                Age, Vehicle Dates, Availability etc
              </li>
              <li>Automatically Denies Invalid Requests</li>
              <li>Doesnt Allow You To Double Book</li>
              <li>
                Can View Various Sales Data Such as Total Revenue, Daily
                Revenue, Orders Today and More
              </li>
              <li>View Entire Orders With All Customer Data</li>
              <li>
                Add in Bookings You Get From Elsewhere e.g. Phone So Data
                Remains Consistant
              </li>
              <li>Mark Vehicles Which are Damaged</li>
              <li>
                Update the Customer On Vehicle status e.g. If The Vehicle Is
                Ready
              </li>
              <li>View Master Time Line of Vehicle Bookings</li>
              <li>Get Reminded on Which Vehicles Are Due to go In/out</li>
              <li>
                Customer is Reminded on When Their Booking is Due to Return
              </li>
              <li>View Customer Data Such as Total Orders and Spend</li>
              <li>Easily View Active Vehicles</li>
              <li>
                Organise New Request into: To Be Confirmed; Accepted Awaiting
                Payment, Accepted Payment Received, Rejected
              </li>
              <li>Organise Bookings into Completed or Not Completed</li>
              <li>Filter Through all Orders and Requests</li>
              <li>Easily Edit Vehicle Details Such as Price</li>
              <li>Add Notes to Vehicles e.g Service Reminders</li>
              <li>Share the Link to Your Dealership on Social Media</li>
              <li>Contact Us For Feature Requests</li>
              <li>Soon Customers Can Pay You Through The Site</li>
              <li>
                Soon Can Create Multiple Accounts Per Dealership so Staff and
                Other Owners of Vehicles Can Easily See Data
              </li>
            </div>
            <div className="dealershipLandingPageTableOfFeaturesTopContentRight">
              <li>Click the Get Access Button</li>
              <li>Create a Vehicle and Add Your Requirements</li>
              <li>
                Share The Link to Your Dealership On Your Social Media And Your
                Customers
              </li>
              <li>Add In Up Comings Bookings On Your Manuals Bookings Tab</li>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      <div className="dealershipLandingPageYoutube">
        <h2>Check Out Our Youtube</h2>
        <iframe
          src="https://www.youtube.com/embed/lsvHFXmsAdg?si=wi6x6ZZvUlKCRW6f"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
      <div className="DealershipLandingPageFAQ">
        <h1>Frequently Asked Questions</h1>
        <p>
          Find Answers to Common Questions About Our Services and Policies in
          Our FAQ Section. For Further Help, Contact IMAS-Cars@outlook.com
        </p>
        <p>Alternatively Call Our Sales Representitive</p>
        <div className="DealershipLandingPageFAQBox">
          <div className="DealershipLandingPageFAQBoxAccordion">
            {faqItems.map((item, i) => (
              <div className="DealershipLandingPageFAQBoxItem" key={i}>
                <div
                  className="DealershipLandingPageFAQBoxItemTitle"
                  onClick={() => toggle(i)}
                >
                  <h2>{item.question}</h2>
                  <span>{selected === i ? "-" : "+"}</span>
                </div>
                <div
                  className={
                    selected === i
                      ? "DealershipLandingPageFAQBoxContent showBox"
                      : "DealershipLandingPageFAQBoxContent"
                  }
                >
                  {item.answer}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="Footer">
        <p onClick={handleTermsClick}>Terms And Conditions</p>
        <p onClick={handlePrivClick}>Privacy Policy</p>
        <p onClick={handleCookiesClick}>Cookies</p>
      </div>
    </div>
  );

  return content;
};

export default DealershipLandingPage;
