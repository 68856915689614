import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Login from "./features/auth/Login";
import LoginCustomer from "./features/auth/LoginCustomer";
import UsersList from "./features/users/usersList";
import BookingsList from "./features/bookings/BookingsList";
import DashboardLayout from "./components/DashboardLayout";
import Dashboard from "./features/auth/Dashboard";
import EditUser from "./features/users/EditUser";
import NewUserForm from "./features/users/NewUserForm";
import NewDealershipForm from "./features/users/NewDealershipForm";
import EditVehicle from "./features/vehicles/EditVehicle";
import NewVehicle from "./features/vehicles/NewVehicle";
import EditBooking from "./features/bookings/EditBooking";
import NewBooking from "./features/bookings/NewBooking";
import Prefetch from "./features/auth/Prefetch";
import PersistLogin from "./features/auth/PersistLogin";
import RequireAuth from "./features/auth/RequireAuth";
import { ROLES } from "./config/roles";
import useTitle from "./hooks/useTitle";
import VehicleDetails from "./features/vehicles/VehicleDetails";
import DashboardLayoutCustomer from "./components/DashboardLayoutCustomer";
import DashboardCustomerHome from "./features/auth/DashboardCustomerHome";
import DashboardCustomerProfile from "./features/customerDashboard/DashboardCustomerProfile";
import DashboardCustomerOrders from "./features/customerDashboard/DashboardCustomerOrders";
import DashboardCustomerReminders from "./features/customerDashboard/DashboardCustomerReminders";
import DashboardCustomerSupport from "./features/customerDashboard/DashboardCustomerSupport";
import DashboardCustomerOrderPage from "./features/customerDashboard/DashboardCustomerOrderPage";
import DashboardProfile from "./features/auth/dashboard/DashboardProfile";
import DashboardMyOrders from "./features/auth/dashboard/DashboardMyOrders";
import DashboardMyCustomers from "./features/auth/dashboard/DashboardMyCustomers";
import DashboardMyVehicles from "./features/auth/dashboard/DashboardMyVehicles";
import DashboardTransactions from "./features/auth/dashboard/DashboardTransactions";
import DashboardReports from "./features/auth/dashboard/DashboardReports";
import DashboardSupport from "./features/auth/dashboard/DashboardSupport";
import DashboardManualBooking from "./features/auth/dashboard/DashboardManualBooking";
import VehicleTimeLine from "./features/auth/dashboard/VehicleTimeLine";
import IndividualVehicleTimeLine from "./features/auth/dashboard/IndividualVehicleTimeLine";
import CustomerOrders from "./features/auth/dashboard/CustomerOrders";
import MyVehiclePage from "./features/auth/dashboard/MyVehiclePage";
import DueForDelivery from "./features/auth/dashboard/DueForDelivery";
import DueForReturn from "./features/auth/dashboard/DueForReturn";
import DamagedVehicles from "./features/auth/dashboard/DamagedVehicles";
import Account from "./features/auth/Account";
// import DealerVehicleList from "./features/vehicles/DealerVehicleList";
import DealerVehicle from "./components/DealerVehicle";
import ActiveVehicles from "./features/auth/dashboard/ActiveVehicles";
import OrdersToday from "./features/auth/dashboard/OrdersToday";
import CompletedOrders from "./features/auth/dashboard/CompletedOrders";
import ManualBooking from "./features/bookings/ManualBooking";
import ToBeConfirmed from "./features/auth/dashboard/ToBeConfirmed";
import AcceptedPaymentNotReceived from "./features/auth/dashboard/AcceptedPaymentNotReceived";
import AcceptedPaymentReceived from "./features/auth/dashboard/AcceptedPaymentReceived";
import Rejected from "./features/auth/dashboard/Rejected";
import DashboardMyRequestsCustomer from "./features/customerDashboard/DashboardMyRequestsCustomer";
import TermsAndConditions from "./app/Legal/TermsAndConditions";
import PrivacyPolicy from "./app/Legal/PrivacyPolicy";
import CookiesPolicy from "./app/Legal/CookiesPolicy";
import useScrollToTop from "./hooks/useScrollToTop";
import DealershipLandingPage from "./features/auth/payment/DealershipLandingPage";
import DashboardCustomerOrderPage2 from "./features/customerDashboard/DashboardCustomerOrderPage2";

function App() {
  useTitle("IMAS Cars");
  useScrollToTop();
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Public Routes */}
        <Route index element={<Home />} />
        <Route path="vehicle/:vehicleId" element={<VehicleDetails />} />
        <Route path="login" element={<LoginCustomer />} />
        <Route path="login=dealership" element={<Login />} />
        <Route path="account" element={<Account />} />
        <Route path=":ownerId" element={<DealerVehicle />} />

        <Route path="termsAndConditions" element={<TermsAndConditions />} />
        <Route path="privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="cookiesPolicy" element={<CookiesPolicy />} />

        <Route path="users">
          <Route path="newCustomer" element={<NewUserForm />} />
          <Route path="newDealership" element={<NewDealershipForm />} />
        </Route>

        <Route path="dealershipInfo" element={<DealershipLandingPage />} />

        {/* <Route path="bookings">
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Dealership]} />}>
            <Route path="newBooking" element={<NewBooking />} />
            <Route path=":id" element={<EditBooking />} />
          </Route>
        </Route> */}

        {/* Protected Routes */}
        <Route element={<PersistLogin />}>
          <Route element={<Prefetch />}>
            <Route path="users">
              <Route
                element={
                  <RequireAuth
                    allowedRoles={[
                      ROLES.Admin,
                      ROLES.Customer,
                      ROLES.Dealership,
                    ]}
                  />
                }
              >
                <Route path=":id" element={<EditUser />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                <Route index element={<UsersList />} />
                <Route path="bookings" element={<BookingsList />} />
              </Route>
            </Route>

            <Route
              element={
                <RequireAuth allowedRoles={[ROLES.Dealership, ROLES.Admin]} />
              }
            >
              <Route path="dashboard" element={<DashboardLayout />}>
                <Route index element={<Dashboard />} />
                <Route path="newVehicle" element={<NewVehicle />} />
                <Route path=":id" element={<EditVehicle />} />
                <Route path="profile" element={<DashboardProfile />} />
                <Route path="activeVehicles" element={<ActiveVehicles />} />
                <Route path="ordersToday" element={<OrdersToday />} />
                <Route path="completedOrders" element={<CompletedOrders />} />
                <Route path="myOrders" element={<DashboardMyOrders />} />
                <Route
                  path="timeline/:id"
                  element={<IndividualVehicleTimeLine />}
                />
                <Route path="timeline" element={<VehicleTimeLine />} />
                <Route path="myCustomers/:id" element={<CustomerOrders />} />
                <Route path="myCustomers" element={<DashboardMyCustomers />} />
                <Route path="myVehicles/:id" element={<MyVehiclePage />} />
                <Route path="myVehicles" element={<DashboardMyVehicles />} />
                <Route
                  path="transactions"
                  element={<DashboardTransactions />}
                />
                <Route path="support" element={<DashboardSupport />} />
                <Route
                  path="manualBooking"
                  element={<DashboardManualBooking />}
                />
                <Route path="reports" element={<DashboardReports />} />
                <Route
                  path="reports/dueForDelivery"
                  element={<DueForDelivery />}
                />
                <Route path="reports/dueForReturn" element={<DueForReturn />} />
                <Route
                  path="reports/damagedVehicles"
                  element={<DamagedVehicles />}
                />
                <Route
                  path="reports/toBeConfirmed"
                  element={<ToBeConfirmed />}
                />
                <Route
                  path="reports/acceptedPaymentNotReceived"
                  element={<AcceptedPaymentNotReceived />}
                />
                <Route
                  path="reports/acceptedPaymentReceived"
                  element={<AcceptedPaymentReceived />}
                />
                <Route path="reports/rejected" element={<Rejected />} />
              </Route>
            </Route>

            <Route
              element={
                <RequireAuth allowedRoles={[ROLES.Customer, ROLES.Admin]} />
              }
            >
              <Route
                path="/customerdashboard"
                element={<DashboardLayoutCustomer />}
              >
                <Route index element={<DashboardCustomerHome />} />
                <Route path="profile" element={<DashboardCustomerProfile />} />
                <Route path="myOrders">
                  <Route index element={<DashboardCustomerOrders />} />
                  <Route
                    path=":bookingId"
                    element={<DashboardCustomerOrderPage2 />}
                  />
                </Route>
                <Route
                  path="myRequests"
                  element={<DashboardMyRequestsCustomer />}
                />
                <Route
                  path="reminders"
                  element={<DashboardCustomerReminders />}
                />
                <Route path="support" element={<DashboardCustomerSupport />} />
              </Route>
            </Route>

            <Route path="bookings">
              <Route
                element={
                  <RequireAuth
                    allowedRoles={[
                      ROLES.Admin,
                      ROLES.Dealership,
                      ROLES.Customer,
                    ]}
                  />
                }
              >
                <Route path="newBooking" element={<NewBooking />} />
              </Route>
            </Route>

            <Route path="bookings">
              <Route
                element={
                  <RequireAuth allowedRoles={[ROLES.Admin, ROLES.Dealership]} />
                }
              >
                <Route path=":id" element={<EditBooking />} />
                <Route path="manualBooking" element={<ManualBooking />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
